import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CropRotateIcon from "@mui/icons-material/CropRotate";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import PermIdIcon from "@mui/icons-material/PermIdentity";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomizeTwoTone";

import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import IconButton from "@mui/material/IconButton";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Dash, Workspace } from "../../types/Dash";
import { Role } from "../../types/Role";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import DashInfoForm from "./DashInfoForm";
import DashCloneForm from "./DashCloneForm";
import DashMoveForm from "./DashMoveForm";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";


//import DashCreationForm from "../generation/DashCreationForm";

import AttachRoleForm from "./AttachRoleForm";

import {
  setViewCodeState,
} from "../editor/dashEditorSlice";

/**
 * A single library item showing a Dash.
 *
 * @export
 * @param {{
 *   dash: Dash;
 * }} props
 * @return {*}
 */
export default function DashLibraryItem(props: {
  dash: Dash;
  workspaceRole: string;
  favourite: boolean;
  workspaces: Array<Workspace> | null;
  roles: Array<Role> | null;
  currentWorkspace: Workspace | null;
}) {
  const actions = useActions();
  const template = props.dash;

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //If the preview is not loaded, load it.
  // if (
  //   !props.documentTemplate.imagePreview ||
  //   (!props.documentTemplate.imagePreview.data === null &&
  //     props.documentTemplate.imagePreview.status === "idle")
  // ) {
  //   console.log("Going to load preview");
  //   // // Asynchronously get all previews.
  //   actions.getDocumentImagePreview(props.documentTemplate);
  //   }
  // });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [infoOpen, setInfoOpen] = React.useState(false);
  const [cloneOpen, setCloneOpen] = React.useState(false);
  const [moveOpen, setMoveOpen] = React.useState(false);

  const [attachRoleOpen, setAttachRoleOpen] = React.useState(false);

  const [generateOpen, setGenerateOpen] = React.useState(false);

  const handleDocumentTemplateClicked = () => {
    dispatch(setViewCodeState(1));
    
    actions.setEditingDash(props.dash);
  };

  const handleEditClicked = () => {
    setAnchorEl(null);

    dispatch(setViewCodeState(1));

    actions.setEditingDash(props.dash);
  };

  const handleDeleteClicked = () => {
    if (window.confirm("Are you sure you want to delete the workbook?")) {
      setAnchorEl(null);
      actions.deleteDash(props.dash);
    }
  };

  const handleFavoriteClicked = () => {
    //setAnchorEl(null);
    //actions.favouriteDocumentTemplate(props.documentTemplate);
  };

  const handleUnfavoriteClicked = () => {
    //setAnchorEl(null);
    //actions.unfavouriteDocumentTemplate(props.documentTemplate);
  };
  
  const handleInfoClicked = () => {
    setAnchorEl(null);
    setInfoOpen(true);
  };

  const handleInfoClosedClicked = () => {
    setAnchorEl(null);
    setInfoOpen(false);
  };

  const handleCloneToWorkspace = () => {
    setCloneOpen(true);
    setAnchorEl(null);
  };

  const handleMoveToWorkspace = () => {
    setMoveOpen(true);
    setAnchorEl(null);
  };

  const handleAttachRoleOpen = () => {
    setAttachRoleOpen(true);
    setAnchorEl(null);
  };

  const handleAttachRoleClose = () => {
    setAttachRoleOpen(false);
  };

  let itemContent = (
    <CircularProgress size="150px" style={{ padding: "10px" }} />
  );

  itemContent = (
    <DashboardCustomizeIcon
        color={"primary"}
        sx={{ fontSize: 96 }}
      />
  );
  /*
  src={URL.createObjectURL(
          props.dash.imagePreview?.data as Blob
        )}
  if (
    !props.dash.imagePreview ||
    props.dash.imagePreview.data == null ||
    props.dash.imagePreview.status === "failed"
  ) {
    // Show the file icon
    itemContent = (
      <InsertDriveFileIcon
        color={isDeleted ? "inherit" : "primary"}
        sx={{ fontSize: 96 }}
      />
    );
  } else if (props.dash.imagePreview?.data !== null) {
    
  }*/

  function getRole(id:number) {
    if (props.roles !== null) {
      const matches = props.roles.filter(_ => _.id == id);

      if (matches.length == 0) {
        return null;
      }
      else {
        return matches[0];
      }
    }
    else {
      return null;
    }
  }

  function dashRoles() {
    const list:Array<Role> = [];

    props.dash.dashes_roles.forEach(_ => {
      const res = getRole(_.role_id);

      if (res !== null) {
        list.push(res);
      }
    });

    return list;
  }

  return (
    <ImageListItem
      key={template.id}
      sx={{
        justifyContent: "center",
        display: "flex",
        width: 200,
        height: 250,
      }}
    >
      
      <Paper
        sx={{
          justifyContent: "center",
          display: "flex",
          width: 200,
          height: 250,
        }}
      >
        
        
        { /*<Badge
          badgeContent={""}
          color={"success"}
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
        >
          
      </Badge> */}
        <IconButton
            data-index={template.id}
            key={template.id}
            onClick={(e) => handleDocumentTemplateClicked()}
            
          >
            
            {itemContent}
          </IconButton>
      </Paper>
      <ImageListItemBar
        title={template.name}
        subtitle={
          <span>
            <b>{ /*template.terms */}</b>
          </span>
        }
        position="below"
        actionIcon={
          <>
            <IconButton
              aria-label={`info about ${template.name}`}
              onClick={handleOpenMenu}
            >
              <MoreVertIcon />
            </IconButton>
            { /*<IconButton
              key="heart"
              sx={{ color: props.favourite? "blue" : "black"}}
              onClick={ () => {
                if (props.favourite) {
                  handleUnfavoriteClicked()
                }
                else {
                  handleFavoriteClicked()
                }
              }}
              >
              { props.favourite && <FavoriteIcon /> }
              { !props.favourite && <FavoriteBorderIcon /> }
            </IconButton> */}
          </>
        }
        
      >
        
        </ImageListItemBar>
      
      { props.workspaceRole !== undefined && props.workspaceRole != "FREE" &&
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}

      >
        <MenuItem onClick={handleEditClicked}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClicked}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        { /*
        { !props.favourite && <MenuItem onClick={handleFavoriteClicked}>
          <ListItemIcon>
            <FavoriteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Favorite</ListItemText>
        </MenuItem> }
        { props.favourite && <MenuItem onClick={handleUnfavoriteClicked}>
          <ListItemIcon>
            <FavoriteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Unfavorite</ListItemText>
      </MenuItem> } */ }
        
        
        <MenuItem onClick={handleInfoClicked}>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Dashboard Properties...</ListItemText>
        </MenuItem>
        { /*<MenuItem onClick={handleMoveToWorkspace} >
          <ListItemIcon>
            <CopyAllIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Move to Workspace</ListItemText>
        </MenuItem> */ }
        <MenuItem onClick={handleAttachRoleOpen} >
          <ListItemIcon>
            <CopyAllIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Attach Role</ListItemText>
        </MenuItem>
        {dashRoles().map(__ => <MenuItem key={"DashRole_" + __.id}>
          <ListItemIcon>
            <PermIdIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{ __.name }</ListItemText>
        </MenuItem> )}
      </Menu>
      
      }
      { attachRoleOpen &&
        <AttachRoleForm
          currentWorkspace={props.currentWorkspace}
          parentCloseHandle={handleAttachRoleClose}
          roles={props.roles!}
          dash={props.dash}
          folder={null}
        ></AttachRoleForm> }
      { infoOpen &&
        <DashInfoForm
          parentCloseHandle={handleInfoClosedClicked}
          dash={props.dash}
        ></DashInfoForm> }
    </ImageListItem>
  );
}
