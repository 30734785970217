import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FileIcon from "@mui/icons-material/FileOpen";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CropRotateIcon from "@mui/icons-material/CropRotate";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import SendIcon from "@mui/icons-material/Send";
import PermIdIcon from "@mui/icons-material/PermIdentitySharp";

import useActions, { useAppDispatch, useAppSelector } from "../../app/hooks";
import IconButton from "@mui/material/IconButton";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Tooltip from "@mui/material/Tooltip";
import { Workspace } from "../../types/Dash";
import { Folder } from "../../types/Folder";
import { Role } from "../../types/Role";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import DashInfoForm from "./DashInfoForm";
import DashCloneForm from "./DashCloneForm";
import DashMoveForm from "./DashMoveForm";
import Badge from "@mui/material/Badge";
import CircularProgress from "@mui/material/CircularProgress";
//import DashCreationForm from "../generation/DashCreationForm";

import AttachRoleForm from "./AttachRoleForm";
import { RollerShades } from "@mui/icons-material";

import {
  setRoleId,
  setUsersInRole,
} from "./librarySlice";

import {
  setFileDataChanged,
  setViewCodeState,
} from "../editor/dashEditorSlice";

/**
 * A single library item showing a Dash.
 *
 * @export
 * @param {{
 *   dash: Dash;
 * }} props
 * @return {*}
 */
export default function DashLibraryFile(props: {
  workspaceRole: string;
  file: string;
  repoId: number;
  workspaces: Array<Workspace> | null;
  currentWorkspace: Workspace | null;
  folderName: string;
}) {
  const actions = useActions();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [infoOpen, setInfoOpen] = React.useState(false);

  const handleFolderClicked = () => {
    const location = props.folderName == "/" ? props.file : props.folderName + "/" + props.file;

    dispatch(setFileDataChanged(false));

    dispatch(setViewCodeState(1));

    actions.openFile({ repoId: props.repoId, file_name: location });
  };

  const handleInfoClicked = () => {
    setAnchorEl(null);
    setInfoOpen(true);
  };
  
  return (
    <ListItem
      sx={{bgcolor: 'background.paper', marginBottom: "10px", width: "60vw"}}
      secondaryAction={
        <IconButton aria-label="comment"
          onClick={handleOpenMenu}>
          <FileIcon />
        </IconButton>
      }
    >
      <ListItemText primary={props.file} onClick={(e) => handleFolderClicked()}/>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}

      >
        <MenuItem onClick={handleInfoClicked}>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>File Properties...</ListItemText>
        </MenuItem>
      </Menu>
  </ListItem>
  );
}
