import { createAction } from "@reduxjs/toolkit";
import {
  ApiCall,
  ApiCallCreateRequest,
  Chart,
  ChartCreateRequest,
  Dash,
  DashCreateRequest,
  DataTable,
  DataTableCreateColumnRequest,
  DataTableCreateRequest,
  DataTableCreateRowRequest,
  Navigation,
  NavigationCreateRequest,
  Parameter,
  ParameterCreateRequest,
} from "../types/Dash";
import {
  Organization,
  User,
  Workspace,
  WorkspaceCreateRequest,
} from "../types/User";

import {
  Folder,
  FolderCreateRequest,
} from "../types/Folder";

import {
  Role,
  RoleCreateRequest,
} from "../types/Role";

import {
  Repo,
  RepoCreateRequest,
} from "../types/Repo";

export const login = createAction<{ email: string; pass: string }>("login");
export const reset = createAction<{ email: string; }>("reset");
export const finalizeReset = createAction<{ password: string; token: string}>("finalizeReset");

export const changePassword = createAction<{ email:string; password: string}>("changePassword");


// Organizations
export const getOrganizations = createAction("getOrganizations");
export const setOrganization = createAction<Organization>("setOrganization");
export const getOrganizationUsers = createAction("getOrganizationUsers");

// Workspaces
export const getWorkspace = createAction<number>("getWorkspace");
export const getWorkspaces = createAction("getWorkspaces");
export const getWorkspaceSummary = createAction("getWorkspaceSummary");
export const getWorkspaceSummarySearch = createAction<string>("getWorkspaceSummarySearch");
export const setWorkspace = createAction<Workspace>("setWorkspace");
export const deleteWorkspace = createAction<Workspace>("deleteWorkspace");
export const createWorkspace =
  createAction<WorkspaceCreateRequest>("createWorkspace");
export const attachUser = createAction<{
  workspace: Workspace;
  user: User;
  role: string;
}>("attachUser");
export const createUser = createAction<{
  workspace: Workspace;
  email: string;
  password: string;
}>("createUser");

export const createUserInOrg = createAction<{
  email: string;
  password: string;
  workspaces: Array<{id: number, role: string}>;
}>("createUserInOrg");

export const createUserWithOrg = createAction<{
  email: string;
  organization_name: string;
}>("createUserWithOrg");

export const detachUser = createAction<{ workspace: Workspace; user: User }>(
  "detachUser"
);
export const changeUserRole = createAction<{ role:string; user_id: number; workspace: Workspace | undefined }>(
  "changeUserRole"
);

// Folders
export const createFolder =
  createAction<FolderCreateRequest>("createFolder");
export const getFolders = createAction("getFolders");
export const changeFolder = createAction<{id:number, name:string}>("changeFolder");
export const backFolder = createAction("backFolder");

// Roles
export const createRole =
  createAction<RoleCreateRequest>("createRole");
export const getRoles = createAction("getRoles");
export const getUsersInRole = createAction<number>("getUsersInRole");
export const linkRole =
  createAction<{roleId: number, userId: number}>("linkRole");
export const unlinkRole =
  createAction<{roleId: number, userId: number}>("unlinkRole");
export const linkDashToRole =
  createAction<{dashId: number, roleId: number}>("linkDashToRole");
export const linkFolderToRole =
  createAction<{folderId: number, roleId: number}>("linkFolderToRole");

// Repos
export const createRepo =
  createAction<RepoCreateRequest>("createRepo");
export const getRepos = createAction("getRepos");

export const pullRepo = 
  createAction<number>("pullRepo");
export const commitRepo = 
  createAction<{repoId: number, message: string}>("commitRepo");
export const pushRepo = 
  createAction<number>("pushRepo");

export const fileList = 
  createAction<{id: number, folder: string}>("fileList");
export const openFile = 
  createAction<{repoId: number, file_name: string}>("openFile");
export const createRepoFile = 
  createAction<{repoId: number, file_name: string, file_path: string}>("createRepoFile");
export const createRepoFolder = 
  createAction<{repoId: number, folder_name: string, file_path: string}>("createRepoFolder");

// Dashes
export const getDashes = createAction("getDashes");
export const createDash =
  createAction<DashCreateRequest>("createDash");
export const updateDash = createAction<Dash>(
  "updateDash"
);
export const updateDashFile = createAction<{dashId: number, currentCode:string }>(
  "updateDashFile"
);
export const previewDash = createAction<Dash>(
  "previewDash"
);
export const getDashImagePreview = createAction<Dash>(
  "getDashImagePreview"
);

export const deleteDash = createAction<Dash>(
  "deleteDash"
);
export const deleteFolder = createAction<Folder>(
  "deleteFolder"
);
export const cloneDash = createAction<{dash: Dash, target_workspace_id: number}>(
  "cloneDash"
);
export const moveDash = createAction<{dash: Dash, target_workspace_id: number}>(
  "moveDash"
);

export const undeleteDash = createAction<Dash>(
  "undeleteDash"
);


// Editor

export const setEditingDash = createAction<Dash>(
  "setEditingDash"
);
export const setEditingDataTable = createAction<number>(
  "setEditingDataTable"
);
export const createChart =
  createAction<ChartCreateRequest>("createChart");
export const createNavigation =
  createAction<NavigationCreateRequest>("createNavigation");
export const createApiCall =
  createAction<ApiCallCreateRequest>("createApiCall");
  export const updateApiCall =
createAction<ApiCallCreateRequest>("updateApiCall");
  export const executeApiCall =
  createAction<ApiCall>("executeApiCall");
export const createDataTable =
  createAction<DataTableCreateRequest>("createDataTable");
export const createDataTableColumn =
  createAction<DataTableCreateColumnRequest>("createDataTableColumn");
export const createDataTableRow =
  createAction<DataTableCreateRowRequest>("createDataTableRow");
export const createParameter =
  createAction<ParameterCreateRequest>("createParameter");
export const setPageNo = 
  createAction<number>("setPageNo");
export const attachChart = createAction<{
  sourceDocumentChartId: number;
  componentOrder: number;
}>("attachChart");
export const updateChart = createAction<Chart>("updateChart");
export const updateChartCode = createAction<{id: number, code: string}>("updateChartCode");
export const executeChartCode = createAction<{chart: Chart, code: string, parameters: string}>("executeChartCode");
export const createChartAboveChart = createAction<{
  belowChart: Chart;
  insertedChart: ChartCreateRequest;
}>("createChartnAboveChart");
export const switchCharts = createAction<{
  sourceChart: Chart;
  destinationChart: Chart;
}>("switchChart");
export const deleteChart = createAction<Chart>(
  "deleteChartChart"
);

/*
export const getVariables = createAction("getVariables");
export const createVariable =
  createAction<VariableCreateRequest>("createVariable");
export const updateVariable = createAction<Variable>("updateVariable");
export const deleteVariable = createAction<Variable>("deleteVariable");
*/
