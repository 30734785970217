import { client } from "./client";
import {
  Repo, RepoCreateRequest,
} from "../types/Repo";

export function apiCreateRepo(
  organizationId: number,
  workspaceId: number,
  repoCreateRequest: RepoCreateRequest
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/repos`,
    repoCreateRequest
  );
}

export function apiGetRepos(
  organizationId: number,
  workspaceId: number
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/repos`
  );
}

export function apiPullRepo(
  organizationId: number,
  workspaceId: number,
  repoId: number,
  ) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/repos/${repoId}/pull`
  );
}

export function apiCommitRepo(
  organizationId: number,
  workspaceId: number,
  repoId: number,
  message: string
  ) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/repos/${repoId}/commit`, { message }
  );
}

export function apiPushRepo(
  organizationId: number,
  workspaceId: number,
  repoId: number,
  ) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/repos/${repoId}/push`
  );
}

export function apiGetFileList(
  organizationId: number,
  workspaceId: number,
  repoId: number,
  folder: string,
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/repos/${repoId}/file_list`,
    { folder_string: folder }
  );
}

export function apiOpenFile(
  organizationId: number,
  workspaceId: number,
  repoId: number,
  file_name: string,
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/repos/${repoId}/open_file`,
    { repo_file_name: file_name }
  );
}

export function apiCreateFile(
  organizationId: number,
  workspaceId: number,
  repoId: number,
  file_name: string,
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/repos/${repoId}/create_file`,
    { repo_file_name: file_name }
  );
}

export function apiCreateRepoFolder(
  organizationId: number,
  workspaceId: number,
  repoId: number,
  folder_name: string,
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/repos/${repoId}/create_folder`,
    { repo_folder_name: folder_name }
  );
}