import { client } from "./client";
import {
  ApiCall,
  ApiCallCreateRequest,
  Chart,
  ChartCreateRequest,
  Dash,
  DashCreateRequest, 
  DataTable,
  DataTableCreateColumnRequest,
  DataTableCreateRequest,
  Navigation,
  NavigationCreateRequest,
  Parameter,
  ParameterCreateRequest,
  Workspace,
} from "../types/Dash";
// TODO: Remove
import { host } from "./client";
import { getLocalUserToken } from "./userAPI";

export function apiGetDashes(
  organizationId: number,
  workspaceId: number,
  currentFolderId: number
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/dashes_by_folder/${currentFolderId}`
  );
}

export function apiCreateDash(
  organizationId: number,
  workspaceId: number,
  dashCreateRequest: DashCreateRequest
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/dashes`,
    dashCreateRequest
  );
}

export function compareChartOrder(a: any, b: any) {
  if (a.chart_order === b.chart_order)
    console.warn(
      `Charts have the same order (${a.component_order}), sorting is not on a unique ID`
    );
  return a.chart_order - b.chart_order;
}

export async function apiGetDash(
  organizationId: number,
  workspaceId: number,
  dashId: number
) {
  const response = await client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/dashes/${dashId}`
  );

  const orig = response.data;

  //console.log("Dash", orig.current_code);

  // Convert the return structure to the correct DocumentTemplate type
  const dash: Dash = Object.assign(orig.dash, {
    charts: orig.charts,
    data_tables: orig.data_tables,
    api_calls: orig.calls,
    navigations: orig.navigations,
    parameters: orig.parameters,

    current_code: orig.current_code,
    proposed_code: orig.proposed_code,
  });

  //console.log(orig.calls);

  // Make a dictionary of sections for efficient assignment later
  const chartDict: { [key: number]: Chart } = {};

  dash.charts?.forEach((chart, index) => {
    chartDict[chart.id] = chart;
  });

  dash.charts!.sort(compareChartOrder);



  return dash;
}

export async function apiGetDataTable(
  organizationId: number,
  workspaceId: number,
  dashId: number,
  dataTableId: number,
) {
  const response = await client.get(
    `/organizations/${organizationId}/dashes/${dashId}/data_tables/${dataTableId}`
  );

  const orig = response.data;

  //console.log("DATA TABLE", orig);

  const dataTable: DataTable = orig.data_table;

  dataTable.columns = orig.column_data;

  return dataTable;
}

export function apiDeleteDash(
  organizationId: number,
  workspaceId: number,
  dashToDelete: Dash
) {
  return client.delete(
    `/organizations/${organizationId}/dashes/${dashToDelete.id}`
  );
}

export function apiCloneDocumentTemplate(
  organizationId: number,
  workspaceId: number,
  dashToClone: Dash,
  targetWorkspaceId: number,
) {
  return client.get(
    `/organizations/${organizationId}/dashes/${dashToClone.id}/clone_to_workspace/${targetWorkspaceId}`
  );
}

export function apiMoveDash(
  organizationId: number,
  workspaceId: number,
  dashToMove: Dash,
  targetWorkspaceId: number,
) {
  return client.get(
    `/organizations/${organizationId}/dashes/${dashToMove.id}/move_to_workspace/${targetWorkspaceId}`
  );
}

export function apiUndeleteDash(
  organizationId: number,
  workspaceId: number,
  dashToDelete: Dash
) {
  return client.get(
    `/organizations/${organizationId}/document_templates/${dashToDelete.id}/undelete`
  );
}

export function apiUpdateDash(
  organizationId: number,
  workspaceId: number,
  dashToUpdate: Dash
) {
  return client.put(
    `/organizations/${organizationId}/workspaces/${workspaceId}/dashes/${dashToUpdate.id}`,
    dashToUpdate
  );
}

export function apiUpdateDashFile(
  organizationId: number,
  workspaceId: number,
  dashId: number,
  fileContents: string,
) {
  return client.post(
    `/organizations/${organizationId}/workspaces/${workspaceId}/dashes/${dashId}/save_code`,
    { data: fileContents}
  );
}


export function apiGetDashImagePreview(
  organizationId: number,
  workspaceId: number,
  dash: Dash
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/dashes/${dash.id}/preview.png`,
    { isBinary: true }
  );
}

/*
export function apiGetVariables(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_variables`
  );
}


export function apiCreateVariable(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  variable: VariableCreateRequest
) {
  return client.post(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_variables`,
    variable
  );
}

export function apiUpdateVariable(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  variable: Variable
) {
  return client.put(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_variables/${variable.id}`,
    variable
  );
}

export function apiDeleteVariable(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  variable: Variable
) {
  return client.delete(
    `/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_variables/${variable.id}`
  );
}
*/

export function apiCreateChart(
  organizationId: number,
  workspaceId: number,
  dash: Dash,
  chart: ChartCreateRequest
) {
  // Add the version
  const payload = {
    ...chart,
  };
  return client.post(
    `/organizations/${organizationId}/dashes/${dash.id}/charts`,
    payload
  );
}

export function apiCreateNavigation(
  organizationId: number,
  workspaceId: number,
  dash: Dash,
  navigation: NavigationCreateRequest
) {
  // Add the version
  const payload = {
    ...navigation,
  };
  return client.post(
    `/organizations/${organizationId}/dashes/${dash.id}/navigations`,
    payload
  );
}

export function apiCreateDataTable(
  organizationId: number,
  workspaceId: number,
  dash: Dash,
  dataTable: DataTableCreateRequest
) {
  // Add the version
  const payload = {
    ...dataTable,
  };
  return client.post(
    `/organizations/${organizationId}/dashes/${dash.id}/data_tables`,
    payload
  );
}

export function apiCreateApiCall(
  organizationId: number,
  dash: Dash,
  apiCall: ApiCallCreateRequest
) {
  // Add the version
  const payload = {
    ...apiCall,
  };
  return client.post(
    `/organizations/${organizationId}/dashes/${dash.id}/calls`,
    payload
  );
}

export function apiUpdateApiCall(
  organizationId: number,
  dash: Dash,
  apiCall: ApiCallCreateRequest,
  apiCallId: number,
) {
  // Add the version
  const payload = {
    ...apiCall,
  };
  return client.put(
    `/organizations/${organizationId}/dashes/${dash.id}/calls/${apiCallId}`,
    payload
  );
}

export function apiExecuteApiCall(
  organizationId: number,
  dash: Dash,
  apiCall: ApiCall
) {
  return client.get(
    `/organizations/${organizationId}/dashes/${dash.id}/calls/${apiCall.id}/execute`
  );
}

export function apiCreateDataTableColumn(
  organizationId: number,
  workspaceId: number,
  dataTableId: number,
  dash: Dash,
  columnData: DataTableCreateColumnRequest
) {
  // Add the version
  const payload = {
    ...columnData,
  };
  return client.post(
    `/organizations/${organizationId}/dashes/${dash.id}/data_tables/${dataTableId}/create_column`,
    payload
  );
}

export function apiCreateDataTableRow(
  organizationId: number,
  workspaceId: number,
  dataTableId: number,
  dash: Dash,
  valuesData: any
) {
  // Add the version
  const payload = {
    ...valuesData,
  };
  return client.post(
    `/organizations/${organizationId}/dashes/${dash.id}/data_tables/${dataTableId}/create_row`,
    payload
  );
}

export function apiCreateParameter(
  organizationId: number,
  workspaceId: number,
  dash: Dash,
  parameter: ParameterCreateRequest
) {
  // Add the version
  const payload = {
    ...parameter,
  };
  return client.post(
    `/organizations/${organizationId}/dashes/${dash.id}/parameters`,
    payload
  );
}

export function apiAttachChart(
  organizationId: number,
  workspaceId: number,
  dash: Dash,
  sourceChartId: number,
  chartOrder: number
) {
  return client.post(
    `/organizations/${organizationId}/dashes/${dash.id}/attach_chart/${sourceChartId}/at/${chartOrder}`,
    {}
  );
}

export function apiGetChart(
  organizationId: number,
  workspaceId: number,
  dash: Dash,
  chartId: number
) {
  return client.get(
    `/organizations/${organizationId}/dashes/${dash.id}/charts/${chartId}`
  );
}

export function apiGetChartData(
  organizationId: number,
  dashId: number,
  dataTableId: number,
  x: string,
  y: string
) {
  console.log(x, y);
  return client.post(
    `/organizations/${organizationId}/dashes/${dashId}/data_tables/${dataTableId}/get_data`,
    {x, y}
  );
}

export function apiUpdateChart(
  organizationId: number,
  workspaceId: number,
  dash: Dash,
  chart: Chart
) {
  //Make sure the section is just the PUT fields
  const chartPayload = {
    id: chart.id,
    name: chart.name,
    chart_order: chart.chart_order,
    chart_type: chart.chart_type,
    data_source: chart.data_source,
    code: chart.code === null ? "" : chart.code,
    code_lang: chart.code_lang,
    page_no: chart.page_no,
    parameter_id: chart.parameter_id,
  };

  return client.put(
    `/organizations/${organizationId}/dashes/${dash.id}/charts/${chart.id}`,
    chartPayload
  );
}

export function apiUpdateChartCode(
  chartId: number,
  code: string,
  organizationId: number,
  workspaceId: number,
  dash: Dash,
) {
  //Make sure the section is just the PUT fields
  const chartPayload = {
    code: code,
  };

  return client.put(
    `/organizations/${organizationId}/dashes/${dash.id}/charts_code/${chartId}`,
    chartPayload
  );
}

export function apiExecuteChartCode(
  chartId: number,
  organizationId: number,
  workspaceId: number,
  dash: Dash,
  parameters: string,
) {

  //console.log("@@@@@");

  return client.post(
    `/organizations/${organizationId}/dashes/${dash.id}/execute_code/${chartId}`,
    {parameters: parameters}
  );
}

export function apiDeleteChart(
  organizationId: number,
  workspaceId: number,
  dash: Dash,
  chartToDelete: Chart
) {
  return client.delete(
    `/organizations/${organizationId}/dashes/${dash.id}/charts/${chartToDelete.id}`
  );  
}

/*
export function apiGetDocumentPreview(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate
) {
  return client.get(
    `/organizations/${organizationId}/workspaces/${workspaceId}/document_templates/${documentTemplate.id}/generate`,
    { isBinary: true }
  );
}
*/

function readFileAsync(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

/*
export async function apiUploadDocumentElementImage(
  organizationId: number,
  workspaceId: number,
  documentTemplate: DocumentTemplate,
  section: DocumentSection,
  subsection: DocumentSubsection,
  documentElement: DocumentElement,
  file: File
) {
  // Temporary construction of the URL until client is refactored.
  const url = `${host}/organizations/${organizationId}/document_templates/${documentTemplate.id}/document_images_png`;
  let headers = {};
  // Content-Type: image/png
  const token = getLocalUserToken();
  if (token) {
    headers = {
      Authorization: token,
      "Content-Type": "application/x-www-form-urlencoded",
    };
  }
  const contentBuffer = await readFileAsync(file);
  const response = await window.fetch(url, {
    method: "POST",
    body: contentBuffer as BodyInit,
    headers: headers,
  });
  const data = await response.json();
  if (response.ok) {
    // Return a result object similar to Axios
    return {
      status: response.status,
      data,
      headers: response.headers,
      url: response.url,
    };
  }
  throw new Error(response.statusText);
}
*/