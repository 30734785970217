import { takeEvery, takeLatest } from "redux-saga/effects";
import * as actions from "./actions";
import { 
  changePassword,
  finalizeReset, 
  login, 
  reset } from "./sagas/auth";
import {
  createUserInOrg,
  createUserWithOrg,
  getOrganizationUsers,
  getOrganizations,
  setOrganization,
} from "./sagas/organizations";
import {
  attachUser,
  changeUserRole,
  createUser,
  createWorkspace,
  detachUser,
  getWorkspace,
  getWorkspaces,
  setCurrentWorkspace,
} from "./sagas/workspaces";
import {
  //cloneDash,
  createDash,
  deleteDash,
  //getDocumentImagePreview,
  getDashes,
  moveDash,
  updateDash,
  updateDashFile,
} from "./sagas/dashes";

import {
  backFolder,
  changeFolder,
  createFolder,
  deleteFolder,
  getFolders,
} from "./sagas/folders";

import {
  createRole,
  getRoles,
  getUsersInRole,
  linkDashToRole,
  linkFolderToRole,
  linkRole,
  unlinkRole,
} from "./sagas/roles";

import {
  commitRepo,
  createRepo,
  createRepoFile,
  createRepoFolder,
  fileList,
  getRepos,
  openFile,
  pullRepo,
  pushRepo,
} from "./sagas/repos";

import {
  attachChart,
  createApiCall,
  createChart,
  createChartAboveChart,
  createDataTable,
  createDataTableColumn,
  createDataTableRow,
  createNavigation,
  createParameter,
  deleteChart,
  executeApiCall,
  executeChartCode,
  setEditingDash,
  setEditingDataTable,
  setPageNoSaga,
  switchCharts,
  updateApiCall,
  updateChart,
  updateChartCode,
} from "./sagas/editor";

export function* rootSaga() {
  yield takeLatest(actions.login.type, login);
  yield takeLatest(actions.reset.type, reset);
  yield takeLatest(actions.finalizeReset.type, finalizeReset);
  yield takeLatest(actions.changePassword.type, changePassword);
  yield takeLatest(actions.getOrganizations.type, getOrganizations);
  yield takeLatest(actions.setOrganization.type, setOrganization);
  yield takeLatest(actions.getOrganizationUsers.type, getOrganizationUsers);

  yield takeLatest(actions.getWorkspace.type, getWorkspace);
  yield takeLatest(actions.getWorkspaces.type, getWorkspaces);

  yield takeLatest(actions.setWorkspace.type, setCurrentWorkspace);
  yield takeLatest(actions.createWorkspace.type, createWorkspace);
  yield takeLatest(actions.attachUser.type, attachUser);
  yield takeLatest(actions.createUser.type, createUser);
  yield takeLatest(actions.createUserInOrg.type, createUserInOrg);
  yield takeLatest(actions.createUserWithOrg.type, createUserWithOrg);
  yield takeLatest(actions.detachUser.type, detachUser);
  yield takeLatest(actions.changeUserRole.type, changeUserRole);


  yield takeLatest(actions.getDashes.type, getDashes);
  yield takeLatest(actions.createDash.type, createDash);

  yield takeLatest(actions.getFolders.type, getFolders);
  yield takeLatest(actions.createFolder.type, createFolder);
  yield takeLatest(actions.changeFolder.type, changeFolder);
  yield takeLatest(actions.backFolder.type, backFolder);

  yield takeLatest(actions.getRoles.type, getRoles);
  yield takeLatest(actions.getUsersInRole.type, getUsersInRole);
  yield takeLatest(actions.createRole.type, createRole);
  yield takeLatest(actions.linkRole.type, linkRole);
  yield takeLatest(actions.unlinkRole.type, unlinkRole);
  yield takeLatest(actions.linkDashToRole.type, linkDashToRole);
  yield takeLatest(actions.linkFolderToRole.type, linkFolderToRole);
  
  yield takeLatest(actions.getRepos.type, getRepos);
  yield takeLatest(actions.createRepo.type, createRepo);
  yield takeLatest(actions.pullRepo.type, pullRepo);
  yield takeLatest(actions.commitRepo.type, commitRepo);
  yield takeLatest(actions.pushRepo.type, pushRepo);
  yield takeLatest(actions.fileList.type, fileList);
  yield takeLatest(actions.openFile.type, openFile);
  yield takeLatest(actions.createRepoFile.type, createRepoFile);
  yield takeLatest(actions.createRepoFolder.type, createRepoFolder);

  yield takeLatest(actions.updateDash.type, updateDash);
  yield takeLatest(actions.updateDashFile.type, updateDashFile);
  yield takeLatest(
    actions.setEditingDash.type,
    setEditingDash
  );
  yield takeLatest(
    actions.setEditingDataTable.type,
    setEditingDataTable
  );
  // NOTE: We are taking every instance as this can be fired in batches.
  // yield takeEvery(
  //  actions.getDocumentImagePreview.type,
  // getDocumentImagePreview
  //);

  
  yield takeLatest(actions.deleteDash.type, deleteDash);
  yield takeLatest(actions.deleteFolder.type, deleteFolder);
  //yield takeLatest(actions.cloneDash.type, cloneDash);
  yield takeLatest(actions.moveDash.type, moveDash);

  yield takeLatest(actions.createChart.type, createChart);
  yield takeLatest(actions.createNavigation.type, createNavigation);
  yield takeLatest(actions.createApiCall.type, createApiCall);
  yield takeLatest(actions.executeApiCall.type, executeApiCall);
  yield takeLatest(actions.updateApiCall.type, updateApiCall);
  yield takeLatest(actions.createDataTable.type, createDataTable);
  yield takeLatest(actions.createDataTableColumn.type, createDataTableColumn);
  yield takeLatest(actions.createDataTableRow.type, createDataTableRow);
  yield takeLatest(actions.createParameter.type, createParameter);
  yield takeLatest(actions.setPageNo.type, setPageNoSaga);
  yield takeLatest(actions.attachChart.type, attachChart);
  yield takeLatest(actions.updateChart.type, updateChart);
  yield takeLatest(actions.updateChartCode.type, updateChartCode);
  yield takeLatest(actions.executeChartCode.type, executeChartCode);
  yield takeLatest(
    actions.createChartAboveChart.type,
    createChartAboveChart
  );
  yield takeLatest(actions.switchCharts.type, switchCharts);
  yield takeLatest(actions.deleteChart.type, deleteChart);



  //yield takeLatest(
  //  actions.uploadDocumentElementImage.type,
  //  uploadDocumentElementImage
  //);

}
