/* WYSIWYG Editing cell, which produces and parses markdown.
Draft.js react editor.
REF: https://jpuri.github.io/react-draft-wysiwyg/#/docs.
REF: https://draftjs.org/docs/api-reference-data-conversion
Exporting and importing reference: https://www.npmjs.com/package/markdown-draft-js
*/
import React,{ useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// Snackbar notification integration. REF: https://www.npmjs.com/package/material-ui-snackbar-provider
import { useDrop } from "react-dnd";

import TextareaAutosize from '@mui/base/TextareaAutosize';

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import EditIcon from "@mui/icons-material/Edit";
import TrashIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from '@mui/material/CircularProgress';
import RunCircleRoundedIcon from "@mui/icons-material/RunCircleRounded";
import SkipNextIcon from "@mui/icons-material/SkipNext";

//import { AxisOptions, Chart } from "react-charts";
import { Chart } from "react-google-charts";
import GoogleMapReact from 'google-map-react';
import ErrorBoundary from "./ErrorBoundary";

import WorldMap from "react-svg-worldmap";

import { 
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import ChartEditForm from "./ChartEditForm";

import {
  Chart as ChartType,
  Dash,
  Parameter
} from "../../../types/Dash";

import useActions, { useAppDispatch, useAppSelector } from "../../../app/hooks";

import Typography from "@mui/material/Typography";
//import { ImageElementEditor } from "./images/ImageElementEditor";
// import { LibraryContentEditor } from "./libraryContent/LibraryContentEditor";
import { ChartAdder } from "./ChartAdder";
import { ViewPreferences } from "../header/preferencesSlice";

import { selectCurrentWorkspaceRole } from "../../auth/authSlice"

import { DebugID } from "./DebugID";
import { DropSkeleton } from "./DropSkeleton";
import { RunCircleRounded } from "@mui/icons-material";
  

import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from '@codemirror/language';
import { rust } from '@codemirror/lang-rust';

import SheetMusicLayout from '../../../react-musicxml/src/index';
import TimeSignature from '../../../react-musicxml/src/index';
import KeySignature from '../../../react-musicxml/src/index';
import Staff from '../../../react-musicxml/src/index';
import Clef from '../../../react-musicxml/src/index';

/*
const SheetMusicExample = () => {
  return (
    <SheetMusicLayout>
      <Staff>
        <Clef name="F-clef" />
        <KeySignature fifths={-2}>
        <TimeSignature beats={2} timeUnit={4} />
      </Staff>
    </SheetMusicLayout>
  );
};*/

const worldMapData = [
  { country: "cn", value: 1389618778 }, // china
    { country: "in", value: 1311559204 }, // india
    { country: "us", value: 331883986 }, // united states
    { country: "id", value: 264935824 }, // indonesia
    { country: "pk", value: 210797836 }, // pakistan
    { country: "br", value: 210301591 }, // brazil
    { country: "ng", value: 208679114 }, // nigeria
    { country: "bd", value: 161062905 }, // bangladesh
    { country: "ru", value: 141944641 }, // russia
    { country: "mx", value: 127318112 }, // mexico
];

function evaluateData(stringOfData: string, chart: ChartType) {
  try {
    if (chart.data !== undefined && chart.data !== null && chart.data.length > 0) {
      return [['x','y'], ...chart.data.map(_ => {
        const result = new Date(parseFloat(_.x));
        return [result, parseFloat(_.y)]
      })];
    }
    else {
      const data = JSON.parse(stringOfData);

      if (data.data !== undefined) {
        //console.log(data);
        return data.data;
      }
      else {
        return [["Awaiting", "Data"], [0, 1]];
      }
    }
  }
  catch {
    return [["Bad", "Data"], [0, 1]];
  }
}

function isData(result:string) {
  if (result !== null && result !== undefined) {
    if (result.length > 10 && result.substring(0,10).toLowerCase() == "graph_line") {
      return true;
    }
    else if (result.length > 5 && result.substring(0, 4).toLowerCase() == "data") {
      return true;
    }
    if (result.length > 5 && result.substring(0,4).toLowerCase() == "html") {
      return true;
    }
  }

  return false;
}

function DataTable(props:{
  source:string}) {
  //try {
    const source =props.source;
    const data = JSON.parse(source.substring(11));

    const headings = data[0] as Array<string>;

    data.shift();

    const columns = useMemo<MRT_ColumnDef<Array<any>>[]>(
      () => 
        headings.map((_,i) =>
      ({
        accessorFn: (b) => b[i],
        header: headings[i],
        size: 150,
      }))
      /*
      {
        accessorFn: (b) => b[1],
        header: headings[1],
        size: 150,
      }*/
      ,[source]
    );

    const table = useMaterialReactTable({
      columns,
      data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
      });
      
    return <MaterialReactTable table={table} />;
  //}
  //catch (exception:any) {
  //  return <p>Error {exception.message}</p>
  //}
}

function ProcessOutput(props: {
  result:string,
  chart:ChartType,
}) {
  const result = props.result;

  if (result !== null && result !== undefined) {
    if (result.length > 10 && result.substring(0,10).toLowerCase() == "graph_line") {
      const data = result.substring(13);
      return (<Chart
          chartType="LineChart"
          data={evaluateData(data, props.chart)}
          width="100%"
          height="400px"
          legendToggle
        />);
    }
    else if (result.length > 10 && result.substring(0, 10).toLowerCase() == "data:image") {
      return (<img src={result}></img>);
    }
    else if (result.length > 10 && result.substring(0, 10).toLowerCase() == "data:audio") {
      return (<audio controls={true}>
        <source src={result} />
        </audio>);
    }
    else if (result.length > 5 && result.substring(0, 5).toLowerCase() == "html:") {
      return (
        <div dangerouslySetInnerHTML={{__html:result.substring(7)}}>
        </div>);
    }

    
  }

  return (<Box></Box>);
}

function ProcessTable(props: {
  result:string
}) {
  return (<ErrorBoundary>
    { props.result !== null && props.result !== undefined && props.result.length > 10 && props.result.substring(0, 10).toLowerCase() == "data:table" && <DataTable source={props.result}></DataTable> }
    </ErrorBoundary>);
}

export function ChartEditor(props: {
  organizationId: number;
  workspaceId: number;
  dash: Dash;
  chart: ChartType;
  viewPreferences: ViewPreferences;
  isSelected: boolean;
  from_outside: boolean;
  is_editor:boolean;
  parameters: Array<Parameter>;
  parameter_values: Map<string, string>;
  set_ref: (arg: HTMLDivElement) => void;
  set_parm_value: (parm: string, value:string) => void;

}) {
  const actions = useActions();

  const dispatch = useAppDispatch();

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };

  const [editChartId, setEditChartId] = useState(-1);

  const [code, setCode] = useState(props.chart.code === null ? "" : props.chart.code);
  const [codeChanged, setCodeChanged] = useState(false);

  const [selection, setSelection] = React.useState("---");

  // Used in progress spinner
  const [running, setRunning] = useState<null|string>(null);

  function handleCodeChange(value:string) {
    setCode(value);

    console.log("A");
    if (props.chart.code != code) {
      setCodeChanged(true);
    }
  }

  function handleSelectionChange(e:any) {
    setSelection(e.target.value);

    if (props.chart?.parameter_id !== undefined && props.chart?.parameter_id !== null) {
      const matches = props.parameters.filter(_ => _.id == props.chart?.parameter_id);

      if (matches.length > 0) {
        props.set_parm_value(matches[0].name, e.target.value);
      }
    }
  }

  function handleDeleteChartId() {
    if (window.confirm("Are you sure you want to delete the chart?")) {
      actions.deleteChart(props.chart);
    }
  }

  function updateCode() {

    try {
      actions.updateChartCode({id: props.chart.id, code: code});
    }
    catch {
      //setCodeError("Cannot update code");
    }
  }

  function parseColaResponse(stuff:string) {
    try {
      return JSON.parse(stuff);
    }
    catch {
      return [stuff];
    }
  }

  function runCode() {
    try {
      setRunning(props.chart.updated_at);

      // 01JAN
      const parameters = {};

      props.dash.charts?.forEach(_ => {
        if (_.chart_type == "INPUT") {
          const matches = props.dash.parameters?.filter(__ => __.id == _.parameter_id);

          if (matches !== undefined && matches.length > 0) {
            (parameters as any)[matches[0].name] = props.parameter_values.get(matches[0].name);
          }
        }
      });

      console.log(parameters);

      actions.executeChartCode({chart: props.chart, code: code, parameters: JSON.stringify(parameters)});
    }
    catch {
      //setCodeError("Cannot update code");
    }
  }

  return (

    <Box style={{ width: "100%", marginBottom: "50px", border: "1px solid #FAFAFA", padding: "5px"}}
      ref={ (ref:HTMLDivElement) => props.set_ref(ref) }>
      <Box>
        { !props.from_outside && props.is_editor && <Button onClick={ () => setEditChartId(props.chart.id)}><EditIcon></EditIcon></Button> }
        { !props.from_outside && props.is_editor &&  <Button sx={{color: "red"}} onClick={ () => handleDeleteChartId()}><TrashIcon></TrashIcon></Button> }
        { props.chart.chart_type != "INPUT" && <Typography style={{textAlign: "center", display: "block", background: "#F0F0FF", fontWeight: "bold", padding: "10px"}}>
          { props.chart.name }
        </Typography> }
      </Box>
      <Box style={{ width: "100%", marginBottom: "50px"}}>
        { props.chart.chart_type == "MULTILINE" &&
        <Chart
          chartType="LineChart"
          data={evaluateData(props.chart.data_source, props.chart)}
          width="100%"
          height="400px"
          legendToggle
          options={
            {
              title: props.chart.name
            }
          }
        /> }
        { props.chart.chart_type == "CANDLESTICKS" &&
        <Chart
          chartType="CandlestickChart"
          data={evaluateData(props.chart.data_source, props.chart)}
          width="100%"
          height="400px"
          legendToggle
          options={
            {
              title: props.chart.name
            }
          }
        /> }

        { props.chart.chart_type == "PIE" &&
        <Chart
          chartType="PieChart"
          data={evaluateData(props.chart.data_source, props.chart)}
          options={
            {
              title: props.chart.name
            }
          }
          width={"100%"}
          height={"400px"}
        /> }

        { props.chart.chart_type == "BASICWORLDMAP" &&
        <Box>
          <WorldMap
            color="black"
            title={props.chart.name}
            value-suffix="-"
            size="responsive"
            data={worldMapData}
            richInteraction={true}
          />
        </Box> }

        { props.chart.chart_type == "CODE" &&
        <Box>
          {/* <TextareaAutosize
            onChange={handleCodeChange}
            value={code}
            minRows={16}
            style={{width: "calc(100% - 40px)", margin: "20px", padding: "5px"}}
        /> */}

          { /* CODE EDITOR */}

          { !props.from_outside && props.is_editor &&  <CodeMirror value={code} height="400px" 
            extensions={[rust()]}
            onChange={handleCodeChange}
            style={{borderTop: "1px solid #000000", borderBottom: "1px solid #000000"}} /> }

          { /* /CODE EDITOR */}

          <Box>
          { !props.from_outside && props.is_editor && <Box>
              <Button onClick={updateCode}><SaveIcon></SaveIcon></Button>
              <Button onClick={runCode}><RunCircleRoundedIcon></RunCircleRoundedIcon></Button>
            </Box> }
            <Box style={{textAlign: "center", width: "100%", marginTop: "10px"}}>
              { (running !== null && running == props.chart.updated_at) && <CircularProgress /> }
              { !props.from_outside && props.is_editor && <Typography>Last updated:  { props.chart.updated_at }</Typography> }
            </Box>
          </Box>

          { // Is code 
          
          !isData(props.chart.code_result) && props.chart.code_lang != "COLA" &&
            <Box>
              <pre style={{whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                backgroundColor: "#F0FFF0"}}>
                {props.chart.code_result}
              </pre>
            </Box> }
          { props.chart.code_lang == "COLA" && props.chart.code_result !== null && props.chart.code_result !== "" && 
            <Box>
              { (parseColaResponse(props.chart.code_result) as Array<any>).map( (__, i) =>
              <Box sx={{background: "#EEEEEE", marginBottom: "5px"}}>
                { __.context !== undefined && <Box>
                  <Typography sx={{marginLeft: "10px"}}><strong>{ i + 1} - CONTEXT: { __.context }:</strong></Typography>
                  <Typography sx={{marginLeft: "10px"}}>COMMAND: { __.command }:</Typography>
                  { __.result !== undefined && <Box>
                    <Typography sx={{marginLeft: "10px"}}>RESULT: </Typography>
                    <pre style={{whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                      backgroundColor: "#F0FFF0"}}>
                      { __.result }
                    </pre>
                  </Box> }
                  { __.result === undefined && <Box>
                    <Typography sx={{marginLeft: "10px"}}>NO RESULT</Typography>
                    </Box> }
                </Box> }
                { __.message !== undefined && <Box>
                  <Typography sx={{marginLeft: "10px"}}><strong>{ i + 1} - ERROR: </strong></Typography>
                  <pre style={{whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    backgroundColor: "#FFF0F0"}}>
                    { __.message }
                  </pre>
                </Box> }
              </Box> )}
            </Box> }

          <Box>
            <pre style={{whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              color: "red" }}>
              {props.chart.code_error}
            </pre>
          </Box>

          <Box>
            <ProcessOutput result={props.chart.code_result} chart={props.chart}></ProcessOutput>
            <ProcessTable result={props.chart.code_result}></ProcessTable>
          </Box>
          { /*<TextareaAutosize
            value={props.chart.code_result}
            minRows={16}
            style={{width: "calc(100% - 40px)", margin: "20px", padding: "5px"}}
        /> */}
          
        </Box> }

        { props.chart.chart_type == "INPUT" && props.chart.code_lang == "DROPDOWN" &&
          <TextField
            value={selection}
            onChange={handleSelectionChange}
            fullWidth={true}
            label={props.chart.name}
            select
            sx={{marginTop: "20px"}}
        >
          <MenuItem key={"___input" + props.chart.id + "_"} value={"---"}>Select an option</MenuItem>
          { 
            props.chart.data_source != "" && props.chart.data_source.split(";").map( (_, i) => 
              <MenuItem key={"___input" + props.chart.id + "_" + i} value={_}>{ _ } #{i + 1}</MenuItem>
            ) }
        </TextField> }

        { props.chart.chart_type == "MUSIC" && <Box>

        </Box>
        }
      </Box>
      {
        /*
        
       <Box style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          
        </GoogleMapReact>
        </Box> */ }
      <Box></Box>
      { /*<ChartAdder organizationId={props.organizationId}
          workspaceId={props.workspaceId}
          dash={props.dash}
        ></ChartAdder> */}

       
      <ChartEditForm
        open={editChartId >= 0}
        chart={props.chart}
        dash={props.dash}
        parameters={props.parameters}
        parentCloseHandle={() => {
          setEditChartId(-1);
          //setAnchorEl(null);
        }}
      />
    </Box>
  );
}
