import { delay, fork, select } from "redux-saga/effects";
import { call, put } from "redux-saga/effects";
import { selectUser } from "../../features/auth/authSlice";
import { User } from "../../types/User";
import { Folder, FolderCreateRequest, FolderRole } from "../../types/Folder";
import {
  selectCurrentFolder,
  selectDashes,
  selectFolderNameStack,
  selectFolderStack,
  selectLibrary,
  setCurrentFolderId,
  setError as setDashError,
  setStatus as setDashStatus,
  setDashes,
  setFolderNameStack,
  setFolderStack,
  setFolders,
  
  //setImagePreview,
  updateDashLocal,
} from "../../features/library/librarySlice";
import * as actions from "../actions";

import {
  apiCreateFolder,
  apiDeleteFolder,
  apiGetFolders,
} from "../../services/FolderAPI";

export function* createFolder(
  action: ReturnType<typeof actions.createFolder>
  ) {
  yield put(setDashStatus({ status: "loading", error: undefined }));
  
  try {
    const currentFolderId = (yield select(selectCurrentFolder)) as number;
    const userState = (yield select(selectUser)) as User;
    
    const data = {...action.payload} as FolderCreateRequest;
    data.parent_folder_id = currentFolderId;

    const createResponse = yield call(
      apiCreateFolder,
      userState.default_organization_id,
      userState.default_workspace_id,
      data
    );
  
    yield put(actions.getFolders());
  
      /*
      const response = yield call(
        apiGetFolder,
        userState.default_organization_id,
        userState.default_workspace_id,
        createResponse.data.dash_id
      );
  
      yield put(setDash(response));*/
  
      // Get the document master
  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

export function* getFolders(
  action: ReturnType<typeof actions.getFolders>
  ) {
  yield put(setDashStatus({ status: "loading", error: undefined }));
  
  try {
    yield put(setFolders([]));

    const currentFolderId = (yield select(selectCurrentFolder)) as number;

    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiGetFolders,
      userState.default_organization_id,
      userState.default_workspace_id,
      currentFolderId,
    );

    const allFolders = response.data.folders as Array<Folder>;

    allFolders.forEach(_ => {
      _.folders_roles = [];
    });

    (response.data.folders_roles as Array<FolderRole>).forEach(_ => {
      for (let j = 0; j < allFolders.length; j++) {
        const folder = allFolders[j];
        if (_.folder_id == folder.id) {
          folder.folders_roles.push(_ as FolderRole);
        }
      }
    });

    //console.log("AAA", response);

    // Set the local slice after updating with _type: "record"
    yield put(setFolders(allFolders));
  
    /*
      // If you're editing a document template, pull it again.
      const selectDash = yield select(selectEditingDash);
      if (selectDash)
        yield put(actions.setEditingDash(selectDash));
  
      // Asynchronously get all previews.
      for(let i = 0; i < response.data.dashes.length; i++) {
        const dt = response.data.dashes[i];
        // console.log(`Getting image for ${dt.name}`);
        yield put(actions.getDashImagePreview(dt));
      }*/
  
    yield put(setDashStatus({ status: "idle", error: undefined }));
  } catch (e) {
    yield put(setDashError(String(e)));
    return;
  }
}

export function* changeFolder(
  action: ReturnType<typeof actions.changeFolder>
) {
  yield put(setDashStatus({ status: "loading", error: undefined }));

  try {
    
    yield put(setDashes([]));
    yield put(setCurrentFolderId(action.payload));

    // Update all the document templates.
    yield put(actions.getDashes());
    yield put(actions.getFolders());
  } catch (e) {
    console.error(e);
    yield put(setDashError(String(e)));
    return;
  }
}

export function* backFolder(
  action: ReturnType<typeof actions.changeFolder>
) {
  yield put(setDashStatus({ status: "loading", error: undefined }));

  try {
    const libraryStack = [...((yield select(selectFolderStack)) as Array<number>)];
    const libraryNameStack = [...((yield select(selectFolderNameStack)) as Array<string>)];

    //console.log("BBB", libraryStack);

    libraryStack.pop();
    libraryNameStack.pop();

    //console.log("BBB", libraryStack);

    yield put(setFolderStack(libraryStack));
    yield put(setFolderNameStack(libraryNameStack));

    if (libraryStack.length >= 1) {
      yield put(actions.changeFolder({id:libraryStack[libraryStack.length - 1], name:libraryNameStack[libraryNameStack.length - 1]}));
    }
    else {
      yield put(actions.changeFolder({id:-1, name:""}));
    }
  } catch (e) {
    console.error(e);
    yield put(setDashError(String(e)));
    return;
  }
}

export function* deleteFolder(
  action: ReturnType<typeof actions.deleteFolder>
) {
  yield put(setDashStatus({ status: "loading", error: undefined }));

  try {
    const userState = (yield select(selectUser)) as User;
    const response = yield call(
      apiDeleteFolder,
      userState.default_organization_id,
      userState.default_workspace_id,
      action.payload
    );
    console.log(response);

    // Update all the document templates.
    yield put(actions.getFolders());
  } catch (e) {
    console.error(e);
    yield put(setDashError(String(e)));
    return;
  }
}


