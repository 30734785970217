import React, { useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ApiIcon from "@mui/icons-material/ApiOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import useActions, { useAppSelector } from "../../app/hooks";
import { CloudUpload } from "@mui/icons-material";

import CreateDashForm from "./CreateDashForm";
import CreateFolderForm from "./CreateFolderForm";
import CreateRoleForm from "./CreateRoleForm";
import CreateFileForm from "./CreateFileForm";
import CreateRepoFolderForm from "./CreateRepoFolderForm";
import AttachUserForm from "./AttachUserForm";
import AttachRepoForm from "./AttachRepoForm";
import { selectEditingDash } from "../editor/dashEditorSlice";
import { selectFolderName, selectRepoId, selectReposMode, selectRoleId, selectRolesMode } from "../library/librarySlice";

import {
  Workspace,
} from "../../types/User";


/**
 * The create menu.
 *
 * @export
 * @param {{user: User | null, documentTemplates: DocumentTemplate[]}} props
 * @return {*}
 */
export function CreateMenu(props: {
  // user: User | null;
  // documentTemplates: DocumentTemplate[];
  workspaces: Workspace[] | undefined;
  workspaceId: number | undefined;
}) {
  const actions = useActions();
  const selectedDocumentTemplate = useAppSelector(selectEditingDash);
  const rolesMode = useAppSelector(selectRolesMode);
  const reposMode = useAppSelector(selectReposMode);
  const viewRoleId = useAppSelector(selectRoleId);
  const viewRepoId = useAppSelector(selectRepoId);
  const viewRepoPath = useAppSelector(selectFolderName);

  //console.log("A", viewRepoPath);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorGitEl, setAnchorGitEl] = React.useState<null | HTMLElement>(null);

  // Create menu details
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Git menu details
  const handleOpenGitMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorGitEl(event.currentTarget);
  };

  const handleCloseGitMenu = () => {
    setAnchorGitEl(null);
  };

  const handleCommit = () => {
    const message = window.prompt("Commit message?");
    if (message !== null && message !== undefined && message != "") {
      actions.commitRepo({repoId: viewRepoId, message: message});
    }
  }

  const handlePush = () => {
    actions.pushRepo(viewRepoId);
  }

  const [createWorkbookFormOpen, setCreateWorkbookFormOpen] = React.useState<{ open: boolean}>({ open: false });
  const [createFolderFormOpen, setCreateFolderFormOpen] = React.useState<{ open: boolean}>({ open: false });
  const [createRoleFormOpen, setCreateRoleFormOpen] = React.useState<{ open: boolean}>({ open: false});
  const [createFileOpen, setCreateFileOpen] = React.useState<{ open: boolean}>({ open: false});
  const [createRepoFolderOpen, setCreateRepoFolderOpen] = React.useState<{ open: boolean}>({ open: false});
  const [attachUserFormOpen, setAttachUserFormOpen] = React.useState<{ open: boolean}>({ open: false});
  const [attachRepoFormOpen, setAttachRepoFormOpen] = React.useState<{ open: boolean}>({ open: false});
  const [importTemplateFormOpen, setImportTemplateFormOpen] =
    React.useState(false);
  const [createDocumentFormOpen, setCreateDocumentFormOpen] = React.useState(false);

  const handleClickCreateDocument = () => {
    setAnchorEl(null);
    setCreateDocumentFormOpen(true)
  };

  const handleClickCreateAdhocDocument = () => {
    setAnchorEl(null);
    // if (props.user?.default_organization_id !== org.id)
    //   actions.setOrganization(org);
  };

  const handleClickImportTemplate = () => {
    setAnchorEl(null);
    setImportTemplateFormOpen(true);
    // if (props.user?.default_organization_id !== org.id)
    //   actions.setOrganization(org);
  };

  const handleClickImportAdhocDocument = () => {
    setAnchorEl(null);
    // if (props.user?.default_organization_id !== org.id)
    //   actions.setOrganization(org);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Create Templates and Documents">
        <Button
          startIcon={<AddIcon />}
          sx={{ color: "white" }}
          onClick={handleOpenMenu}
        >
          Create
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        { !rolesMode && !reposMode && viewRoleId < 0 && <MenuItem key="createWorkbook" onClick={() => setCreateWorkbookFormOpen({open: true})}>
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Create Workbook</ListItemText>
        </MenuItem> }
        { !rolesMode && !reposMode && viewRoleId < 0 && <MenuItem key="createFolder" onClick={() => setCreateFolderFormOpen({open: true})}>
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Create Folder</ListItemText>
        </MenuItem> }
        { rolesMode && viewRoleId < 0 && <MenuItem key="createRole" onClick={() => setCreateRoleFormOpen({open: true})}>
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Create Role</ListItemText>
        </MenuItem> }
        { rolesMode && viewRoleId > 0 && <MenuItem key="attachUser" onClick={() => setAttachUserFormOpen({open: true})}>
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Attach User to Role</ListItemText>
        </MenuItem> }
        { reposMode && viewRepoId < 0 && <MenuItem key="attachRepo" onClick={() => setAttachRepoFormOpen({open: true})}>
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Attach a Code Repository</ListItemText>
        </MenuItem> }
        { reposMode && viewRepoId > 0 && <MenuItem key="createFile" onClick={() => setCreateFileOpen({open: true})}>
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Create a New File</ListItemText>
        </MenuItem> }
        { reposMode && viewRepoId > 0 && <MenuItem key="createRepoFolder" onClick={() => setCreateRepoFolderOpen({open: true})}>
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Create a New Folder</ListItemText>
        </MenuItem> }
       
      </Menu>


      { reposMode && viewRepoId > 0 && <Tooltip title="Git actions">
        <Button
          startIcon={<ApiIcon />}
          sx={{ color: "white" }}
          onClick={handleOpenGitMenu}
        >
          Git
        </Button>
      </Tooltip> }
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorGitEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorGitEl)}
        onClose={handleCloseGitMenu}
      >
        <MenuItem key="commitGit" onClick={() => handleCommit() }>
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Commit changes...</ListItemText>
        </MenuItem>

        <MenuItem key="pushGit" onClick={() => handlePush() }>
          <ListItemIcon>
            <AddIcon></AddIcon>
          </ListItemIcon>
          <ListItemText>Push changes...</ListItemText>
        </MenuItem>
       
      </Menu>



      <CreateDashForm
        open={createWorkbookFormOpen.open}
        parentCloseHandle={() => {
          setCreateWorkbookFormOpen({open: false});
          setAnchorEl(null);
        }}
      />
      <CreateFolderForm
        open={createFolderFormOpen.open}
        parentCloseHandle={() => {
          setCreateFolderFormOpen({open: false});
          setAnchorEl(null);
        }}
      />
      <CreateRoleForm
        open={createRoleFormOpen.open}
        parentCloseHandle={() => {
          setCreateRoleFormOpen({open: false});
          setAnchorEl(null);
        }}
      />
      <CreateFileForm
        open={createFileOpen.open}
        repoId={viewRepoId}
        repoPath={viewRepoPath}
        parentCloseHandle={() => {
          setCreateFileOpen({open: false});
          setAnchorEl(null);
        }}
      />
      <CreateRepoFolderForm
        open={createRepoFolderOpen.open}
        repoId={viewRepoId}
        repoPath={viewRepoPath}
        parentCloseHandle={() => {
          setCreateRepoFolderOpen({open: false});
          setAnchorEl(null);
        }}
      />
      { props.workspaces !== undefined && props.workspaceId !== undefined && <AttachUserForm
        open={attachUserFormOpen.open}
        workspaces={props.workspaces}
        workspaceId={props.workspaceId}
        roleId={viewRoleId}
        parentCloseHandle={() => {
          setAttachUserFormOpen({open: false});
          setAnchorEl(null);
        }}
      /> }

      { props.workspaces !== undefined && props.workspaceId !== undefined && <AttachRepoForm
        open={attachRepoFormOpen.open}
        parentCloseHandle={() => {
          setAttachRepoFormOpen({open: false});
          setAnchorEl(null);
        }}
      /> }

    </Box>
  );
}
